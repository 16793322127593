/* FontList.css */

/* Font-family thư pháp số 1 */
@font-face {
    font-family: 'Font1';
    src: local(''), url('../../assets/font/Uni\ Thuphap\ \(30\).ttf') format('truetype');
}

@font-face {
    font-family: 'Font2';
    src: local(''), url('../../assets/font/THUPHAPTHANHCONG.TTF') format('truetype');
}

@font-face {
    font-family: 'Font3';
    src: local(''), url('../../assets/font/TLM\ Expectation\ Std\ Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Font4';
    src: local(''), url('../../assets/font/Trirong-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'Font5';
    src: local(''), url('../../assets/font/Uni Thuphap (13).TTF') format('truetype');
}

@font-face {
    font-family: 'Font6';
    src: local(''), url('../../assets/font/Uni Thuphap (14).ttf') format('truetype');
}

@font-face {
    font-family: 'Font7';
    src: local(''), url('../../assets/font/Uni Thuphap (15).ttf') format('truetype');
}

@font-face {
    font-family: 'Font8';
    src: local(''), url('../../assets/font/Uni Thuphap (16).ttf') format('truetype');
}

@font-face {
    font-family: 'Font11';
    src: local(''), url('../../assets/font/Tropen-Deco.ttf') format('truetype');
}

@font-face {
    font-family: 'Font16';
    src: local(''), url('../../assets/font/UVF\ MussicaSwash.ttf') format('truetype');
}

@font-face {
    font-family: 'DongHo';
    src: local(''), url('../../assets/font/Haiku_s\ Script\ version\ 09\ Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Font28';
    src: local(''), url('../../assets/font/Happy\ Birthday\,\ Baby.ttf') format('truetype');
}

@font-face {
    font-family: 'Font34';
    src: local(''), url('../../assets/font/HP0015HN.ttf') format('truetype');
}

@font-face {
    font-family: 'Font42';
    src: local(''), url('../../assets/font/HP001\ 5\ hang\ normal.ttf') format('truetype');
}

@font-face {
    font-family: 'Font43';
    src: local(''), url('../../assets/font/HP001\ Kieu2\ 5H.TTF') format('truetype');
}

@font-face {
    font-family: 'Font44';
    src: local(''), url('../../assets/font/HP001\ Kieu\ 5H.TTF') format('truetype');
}

@font-face {
    font-family: 'Font88';
    src: local(''), url('../../assets/font/iCielTimberline-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Font89';
    src: local(''), url('../../assets/font/SAMSUNGSHARPSANS-BOLD_SMCPS.TTF') format('truetype');
}

@font-face {
    font-family: 'Font90';
    src: local(''), url('../../assets/font/SAMSUNGSHARPSANS-REGULAR_SMCPS.TTF') format('truetype');
}

@font-face {
    font-family: 'Font91';
    src: local(''), url('../../assets/font/SAY-Brotherhood_Script.ttf') format('truetype');
}

@font-face {
    font-family: 'Font92';
    src: local(''), url('../../assets/font/SAY\ -\ Ananda\ Hastakchyar.ttf') format('truetype');
}

@font-face {
    font-family: 'Font93';
    src: local(''), url('../../assets/font/SAY\ -\ Dragon\ is\ coming.ttf') format('truetype');
}

@font-face {
    font-family: 'Font94';
    src: local(''), url('../../assets/font/Circular\ Book.ttf') format('truetype');
}

@font-face {
    font-family: 'Font95';
    src: local(''), url('../../assets/font//SAY\ -\ StolenScript.ttf') format('truetype');
}

@font-face {
    font-family: 'Font96';
    src: local(''), url('../../assets/font/SAYURI-Niewe.ttf') format('truetype');
}

@font-face {
    font-family: 'Font97';
    src: local(''), url('../../assets/font/SAYURI\ -\ BrokenMustangs\ 2.ttf') format('truetype');
}

@font-face {
    font-family: 'Font98';
    src: local(''), url('../../assets/font/SAYURI\ -\ Dartangnon.ttf') format('truetype');
}

@font-face {
    font-family: 'Font99';
    src: local(''), url('../../assets/font/Sayuri\ -\ LeGrandSaut.ttf') format('truetype');
}

@font-face {
    font-family: 'Font100';
    src: local(''), url('../../assets/font/Sayuri\ -\ Dubble\ Trubble.ttf') format('truetype');
}

@font-face {
    font-family: 'Font101';
    src: local(''), url('../../assets/font/Sayuri\ -\ OrangeJuice.ttf') format('truetype');
}

@font-face {
    font-family: 'Font102';
    src: local(''), url('../../assets/font/Sayuri\ -\ Znikomit.ttf') format('truetype');
}

@font-face {
    font-family: 'Font103';
    src: local(''), url('../../assets/font/SAYVNBlix\ Black.ttf') format('truetype');
}

@font-face {
    font-family: 'defaultFamily';
    src: local(''), url('../../assets/font/Uni\ Thuphap\ \(30\).ttf') format('truetype');
}

@font-face {
    font-family: 'UTM Beautiful Caps';
    src: local(''), url('../../assets/font/UTM\ Beautiful\ Caps.ttf') format('truetype');
}

@font-face {
    font-family: 'UTM Agin';
    src: local(''), url('../../assets/font/UTM\ Agin.ttf') format('truetype');
}

@font-face {
    font-family: 'UTM Aquarelle';
    src: local(''), url('../../assets/font/UTM\ Aquarelle.ttf') format('truetype');
}

@font-face {
    font-family: 'UTM Billhead 1910';
    src: local(''), url('../../assets/font/UTM\ Billhead\ 1910.ttf') format('truetype');
}

@font-face {
    font-family: 'UTM French Vanilla';
    src: local(''), url('../../assets/font/UTM\ French\ Vanilla.ttf') format('truetype');
}

@font-face {
    font-family: 'UTM ThuPhap Thien An';
    src: local(''), url('../../assets/font/UTM\ ThuPhap\ Thien\ An.ttf') format('truetype');
}

@font-face {
    font-family: 'UTM Yen Tu';
    src: local(''), url('../../assets/font/UTM\ Yen\ Tu.ttf') format('truetype');
}

@font-face {
    font-family: 'UTM Ong Do Gia';
    src: local(''), url('../../assets/font/UTM\ Ong\ Do\ Gia.ttf') format('truetype');
}

@font-face {
    font-family: 'UTM Ong Do Tre';
    src: local(''), url('../../assets/font/UTM\ Ong\ Do\ Tre.ttf') format('truetype');
}