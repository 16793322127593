@font-face {
    font-family: 'DongHo';
    src: local(''), url('../../../assets/font/Uni Thuphap (30).ttf') format('truetype'); /* format('truetype');

     /* unicode-range: U+0020-U+002F, U+0030-U+0039, U+003A-U+0040, U+0041-U+005A, U+005B-U+0060, U+0061-U+007A, U+007B-U+007E, U+00C0-U+00C3, U+00C8-U+00CA, U+00CC-U+00CD, U+00D0, U+00D2-U+00D5, U+00D9-U+00DA, U+00DD, U+00E0-U+00E3, U+00E8-U+00EA, U+00EC-U+00ED, U+00F2-U+00F5, U+00F9-U+00FA, U+00FD, U+0102-U+0103, U+0110-U+0111, U+0128-U+0129, U+0168-U+0169, U+01A0-U+01B0, U+1EA0-U+1EF9, U+02C6-U+0323; / */

    /* unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; / */
}

.cssTitle {
    /* font-family: DongHo; */
    font-size: 40px;
}

@media only screen and (max-width: 768px) {
    .cssTitle {
        font-size: 35px;
    }
}

@media only screen and (max-width: 640px) {
    .cssTitle {
        font-size: 22px;
    }
}

@media only screen and (max-width: 480px) {
    .cssTitle {
        font-size: 22px;
    }
}

.cssHomePageTitle {
    /* font-family: DongHo; */
    font-size: 35px;
}
@media only screen and (max-width: 768px) {
    .cssTitle {
        font-size: 35px;
    }
}

@media only screen and (max-width: 640px) {
    .cssTitle {
        font-size: 22px;
    }
}

@media only screen and (max-width: 480px) {
    .cssTitle {
        font-size: 22px;
    }
}
