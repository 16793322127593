/* html {
  font-family: 'Roboto', sans-serif;
  line-height: 1.8;
} */

body {
  margin: 0;
}

/* td {
  border: 1px solid;
} */

#root {
  padding: 0px;
}

/* .MuiSvgIcon-root {
  font-size: 18px !important;
} */

.tippy-box[data-theme~='tomato'] {
  background-color: tomato;
  color: yellow;
}

/* .slate-code_block {
  background-color: #111827 !important;
}

.slate-code_block code {
  color: white;
} */

.drag-button {
  overflow: hidden;
  padding: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  border-style: none;
  cursor: pointer;
  outline: 0;

  min-width: 18px;
  min-height: 18px;
}


.totalPost:hover {
 font-weight: bold;
}

.MuiCardContent-root {
  padding: 10px;
}
.css-17pmaar-MuiCardContent-root:last-child {
 padding-bottom: 10px;
}

div::-webkit-scrollbar {
  width: 0.4em;
}

div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

div::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
} 