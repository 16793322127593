.input-group {
    position: relative;
  }
  
.input-group .input-area {
    outline: none;
    border: 1px solid #ccc;
    padding: 16px 13px;
    /* font-size: 14px; */
    border-radius: 5px;
    /* width: '300px'; */
  }
  
.input-group .input-area:valid+.label {
    top: -8px;
    padding: 0 3px;
    font-size: 14px;
  }
  
.input-group .input-area:focus {
    border: 1.5px solid red;
  }
  
.input-group .label {
    /* color: #8d8d8d; */
    position: absolute;
    top: 20px;
    left: 13px;
    background: #ffffff;
  }
  
.input-group .input-area:focus+.label {
    top: -8px;
    padding: 0 3px;
    font-size: 14px;
    /* color: royalblue; */
  }
  
.input-group .label {
    transition: all 0.2s ease;
  }