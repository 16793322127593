// color variants
@import 'themes-vars.module.scss';
@import 'swiper/swiper.min.css';
@import 'swiper/modules/free-mode/free-mode.min.css';
@import 'swiper/modules/pagination/pagination.min.css';
@import 'swiper/modules/navigation/navigation.min.css';
// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
    .ps__rail-y {

        &:hover>.ps__thumb-y,
        &:focus>.ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }

    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container>.ps {
    &.ps--active-y>.ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;

        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }

    &.ps--scrolling-y>.ps__rail-y,
    &.ps--scrolling-x>.ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes enlargeImage {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes bounce {

    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }

    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }

    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }

    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }

    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {

    0%,
    50%,
    100% {
        transform: translateY(0px);
    }

    25% {
        transform: translateY(-10px);
    }

    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {

    0%,
    50%,
    100% {
        transform: translateX(0px);
    }

    25% {
        transform: translateX(-10px);
    }

    75% {
        transform: translateX(10px);
    }
}

.react-confirm-alert-overlay {
    z-index: 9999 !important;
    background: rgba(0, 0, 0, 0.5) !important;
}


body.react-confirm-alert-body-element {
    overflow: inherit !important;
}

.css-teccuw-MuiToolbar-root-MuiTablePagination-toolbar {
    padding-top: 0px;
    padding-bottom: 0px;
}

.css-1lk92tn-MuiGrid-root>.MuiGrid-item {
    padding-left: 0;
}

// .detail_member {
//     padding: 0 24px;
// }
@media (min-width: 900px) {
    .detail_member {
        padding: 0 24px !important;
    }
}

@media (max-width: 600px) {
    .detail_member {
        padding: 0 5px !important;
    }
}

.tab-info button.MuiTab-labelIcon.Mui-selected {
    background: antiquewhite;
    color: #a83131 !important;
}

.tab-info .MuiTab-root:hover {
    background: #ededed;
}

.tab-info button.MuiButtonBase-root.Mui-disabled {
    color: red;
}

.paginate-member-list .css-teccuw-MuiToolbar-root-MuiTablePagination-toolbar {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
}

.paginate-member-list .MuiTablePagination-root {
    overflow: hidden;
}

@media (min-width: 600px) {
    .css-1oqqzyl-MuiContainer-root {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.css-6jnkwp-MuiButtonGroup-root .MuiButtonGroup-grouped:not(:first-of-type) {
    margin-top: -3px;
}

.css-x1j5ys-MuiGrid-root>.MuiGrid-item {
    padding-left: 3.4px;
}

.css-pana3 .MuiButtonGroup-grouped:not(:first-of-type) {
    margin-top: -3px !important;
}

.css-bpzsuz > .MuiGrid-item {
    padding-left: 4.4px !important;
}