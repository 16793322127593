@media screen and (min-width: 1360px) {
    .divImage {
        height: 270px;
    }
    .box {
        height: 800px;
    }
    .defaul {
        height: auto;
    }
}

@media screen and (min-width: 1920px) {
    .divImage {
        height: 270px;
    }
    .box {
        height: auto;
    }
    .defaul {
        height: auto;
    }
}
